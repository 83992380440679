@import './variables.scss';
@import './_colors.scss';
@import './Common.module';

// Login Component
.mainContent {
    // height: 100vh;
    width: 100vw;
    min-height: 100vh; // Fix for bottom gap on certain sizes
    color: white;
}

// !Login Component
.loginInputField {
    margin-bottom: 24px!important;
    grid-column: 1/ span 4;
    padding: 20px;
}

.loginInputField::placeholder {
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 600;
    color: #555;
}

.loginInfoSpan {
    font-style: italic;
    grid-column: 4/end;
    font-Size: 0.8em;
    align-Self: center;
    margin-Left: 12px;
    padding-Bottom: 10px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    margin: 0 auto 24px auto;
    padding: 24px;
    border-Radius: 4px;
    background: white;
    width: 39vw;
    max-width: 426px;
    @include containerShadow;
}

.center {
    width: 100%;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}

.button {
    min-width: 120px;
    width: 100%;
    height: 36px;
}

.linkButton {
    width: 100%;
}

.helpButton {
    margin-right: 0;
}

@media (max-width: $breakpoint) {
    .login_error_field, .error {
        grid-column: 1/end;
        position: relative;
        width: calc(100% - 24px);
        background-color: $error-background;
        border-radius: 5px;
        color: $error-text;
        line-height: 24px;
        padding: 0 12px;
        margin: 8px 0px;
    }
    .login_success_field, .success {
        grid-column: 1/end;
        position: relative;
        width: calc(100% - 24px);
        background-color: $success-background;
        border-radius: 5px;
        color: $success-text;
        line-height: 24px;
        padding: 0 12px;
        margin: 8px 0px;
    }
}

@media only screen and (max-width: 768px) {
    .formGroup {
        width: 384px;
    }
}

@media only screen and (max-width: 487px) {
    .formGroup {
        width: 295px;
        
    }
}

@media only screen and (max-width: 350px) {
    .formGroup {
        width: 270px;
    }
}
