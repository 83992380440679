@import 'Common.module';

.paymentCard {
  margin: 4vw auto 84px auto;
  padding: 20px;
  max-width: 780px;
  @media all and (max-width: $large) {
      margin: 4vw 20px 84px 20px;
  }
  @media all and (max-width: $small) {
      max-width: 95vw;
      margin: 4vw 10px 84px 10px;
  }
}

.checkMark {
  color: limegreen;
  display: block;
  margin: 2vh auto;
}

.errorMark {
  color: red;
  display: block;
  margin: 2vh auto;
}

.yourCards {
  margin: 4vw auto 84px auto;
  padding: 20px;
  max-width: 80vw;
  @media all and (max-width: $large) {
    margin: 4vw auto 84px auto;
  }
  @media all and (max-width: $small) {
    max-width: 95vw;
    margin: 4vw auto 84px auto;
  }
}

.reviewAppbar {
  grid-column: 1/-1;
  background: $pink;
  margin-bottom: 5vh;
}

.tableLabel {
  max-width: 20%;
  padding: 8px 0;
}

.amount {
  font-weight: 600;
  padding: 8px 0;
}

.buttonContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin: 15px auto;
  @media all and (max-width: $medium) {
    width: 90%;
  }
  @media all and (max-width: $small) {
    width: 100%;
  }
}

.button {
  margin: 25px 0px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
}

.dashboardButton {
  margin: 25px 0px;
  display: flex;
  justify-content: center;
}

.formContainer {
  @include containerShadow;
  height: 100%;
  max-width: 50vw;
  padding: 24px;
  border-radius: 2px;
  margin: 0 auto;
  background-color: white;
  @media only screen and (max-width: 830px) {
    max-width: 70vw;
  }
  @media only screen and (max-width: 600px) {
    padding: 2vh 2vw;
    max-width: 95vw;
  }
}

.marginFix {
  margin-top: 24px;
}

.cardPosFix {
    margin-top: 32px;
}

//  ####################### Latest Invoices  ##################
.invoicesContainer {
  @include mui_card;
  @include containerShadow;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  grid-column: 1/8;
  justify-content: center;
  max-width: calc(100% - 36px);
  width: 100%;

  @media screen and (max-width: $large) {
    grid-column: 1/-1;
  }
}

.row {
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;
  display: grid;
  grid-template-columns: 50% 50%;

  .date {
    color: $text_gray;
    display: inline-block;
    float: left;
  }

  .amount {
    color: $wiline_color;
    display: inline-block;
    justify-self: end;
    float: right;
  }
  @include listShadow;
}

.invoice_row {
  cursor: pointer;
  .file {
    color: $wiline_color;
    display: inline-block;
    justify-self: end;
    float: right;
  }
}

.invoice_row:hover {
  .file, .date {
    font-weight: 600;
  }
}

.sizeFix {
  width: 110px !important;
  padding: 0 !important;
  margin: 2px !important;
}
