@import 'variables';

.link {
  max-height: 100px;
  display: grid;
  grid-template: 50px auto / 1fr;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  text-decoration: none;
}

.link_title {
  text-transform: uppercase;
  font-size: 10;
  font-weight: bold;
}

.logo {
    width: 40vw;
    max-width:420px;
    margin: 0 auto 40px auto;
    padding: 2vh;
}


@media only screen and (max-width: 487px) {
    .logo {
        width:70vw;
        padding-top: 2vh;
    }
}

@media only screen and (max-width: 350px) {
    .logo {
        width:80vw;
        padding-top: 2vh;
    }
}
