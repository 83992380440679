@import './variables.scss';

.welcome {
  background-color: white;
  padding: 3vh 3vw;
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
  box-shadow: inset 0px -3px 3px rgba(0,0,0,0.22);
  @media (max-width: 480px) {
    padding: 1vh 1vw;
    font-size: 1rem;
  }
}

.container {
  //min-height: calc(100vh - 100px);
  overflow: visible;
  padding-top: 3vh;
}

.flex {
  display: flex;
}

.clearBoth {
  clear: both;
}

.icon {
  font-size: 50px;
}

.sectionPaddingTop {
  padding-top: 3vh;
}

.grecaptchaBadge{
  visibility: collapse !important;
}

// Error status on invalid text field input
.error {
  grid-column: 1/end;
  position: relative;
  background-color: $error-background;
  border-radius: 5px;
  color: $error-text;
  line-height: 24px;
  padding: 0 12px;
  margin: 8px 0;
}

.success {
  grid-column: 1/end;
  position: relative;
  background-color: $success-background;
  border-radius: 5px;
  color: $success-text;
  line-height: 24px;
  padding: 0 12px;
  margin: 8px 0;
}

$breakpoint: 710px; // Breakpoint on login page

// Error status on invalid text field input
.login_error_field {
  grid-column: 1/end;
  position: relative;
  width: calc(100% - 24px);
  background-color: $error-background;
  border-radius: 5px;
  color: $error-text;
  line-height: 24px;
  padding: 0 12px;
  margin: 8px 0;
}

.login_success_field {
  grid-column: 1/end;
  position: relative;
  width: calc(100% - 24px);
  background-color: $success-background;
  border-radius: 5px;
  color: $success-text;
  line-height: 24px;
  padding: 0 12px;
  margin: 8px 0;
}

// Loader for cards
.loader_container {
  background-color: white;
  grid-column: 1/-1;

  .loader {
    @include center-all;
    font-size: 30px;
  }
}

// Animated ellipsis for loading buttons
.loading {
  cursor: wait;

  .one {
    opacity: 0;
    animation: dot .8s infinite;
    animation-delay: 0.0s;
  }

.two {
    opacity: 0;
    animation: dot .8s infinite;
    animation-delay: 0.2s;
  }

.three {
    opacity: 0;
    animation: dot .8s infinite;
    animation-delay: 0.3s;
  }
}

@-webkit-keyframes dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes dot {
      0% { opacity: 0; }
     50% { opacity: 0; }
    100% { opacity: 1; }
}

@media all and (max-width: 720px) {
  .mobileHideBreadcrumbs {
    display: none;
  }
}
