// Variables
$wiline_color: rgb(7,68,129);
$white: rgb(256,256,256);
$off_white: #F6F6F6;
$main-text: rgb(10,10,10);
$success-background: #B8FFC3;
$error-background: #F9D6D1;
$box-shadow: #333;
$light_gray: #c4c4c4;
$error-text: #9C2B23;
$success-text: #155229;
$light_blue_bg: #E9F1F8;
$text_gray:  #474747;

// Appbar colors
$pink: rgb(240, 98, 146);
$orange: #FFAA00;
$grey: rgb(120, 144, 156);
$purple: rgb(92, 107, 192);
$text_color: #838383;
$button-bg: #FFAA00;
$button-fg: #474747;

// break points
$small: 512px;
$medium: 768px;
$large: 960px;
$extraLarge: 1280px;
$drawer-break: 602px;

// measurements
$aside-width: 260px;
$app-bar-height: 64px;
$drawer-width: 240px;

// Mixins
@mixin center_all {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin containerShadow {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25),
            0 10px 10px rgba(0,0,0,0.22);
    } 
}

@mixin buttonShadow {
    box-shadow: 0 3px 6px 
        rgba(0,0,0,0.16), 0 3px 6px 
        rgba(0,0,0,0.23);
        &:hover {
            box-shadow: 0 14px 28px
                rgba(0,0,0,0.25), 0 10px 10px
                rgba(0,0,0,0.22);
        }
}

@mixin wordBreak {
    word-wrap: break-all;
    white-space: no-wrap;
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s, transform 0.4s;
    &:hover {
        overflow: visible;
        white-space: normal;
        width: auto;
    }
}

@mixin listShadow {
    transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover {
      border-top: solid 1px $light-gray;
      box-shadow: 0 10px 20px rgba(0,0,0,0.25), 0 7px 7px rgba(0,0,0,0.22);
    }
}


@mixin mui_card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 1rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

@mixin blue_gradient {
  background: rgba(7,68,129,1);
  background: -moz-linear-gradient(top, rgba(7,68,129,1) 0%, rgba(7,68,129,1) 28%, rgba(0,84,168,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(7,68,129,1)), color-stop(28%, rgba(7,68,129,1)), color-stop(100%, rgba(0,84,168,1)));
  background: -webkit-linear-gradient(top, rgba(7,68,129,1) 0%, rgba(7,68,129,1) 28%, rgba(0,84,168,1) 100%);
  background: -o-linear-gradient(top, rgba(7,68,129,1) 0%, rgba(7,68,129,1) 28%, rgba(0,84,168,1) 100%);
  background: -ms-linear-gradient(top, rgba(7,68,129,1) 0%, rgba(7,68,129,1) 28%, rgba(0,84,168,1) 100%);
  background: linear-gradient(to bottom, rgba(7,68,129,1) 0%, rgba(7,68,129,1) 28%, rgba(0,84,168,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#074481', endColorstr='#0054a8', GradientType=0 );
}

