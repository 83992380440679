@import './variables.scss';
@import './_colors.scss';

.container {
  @include mui_card;
  //@include blue_gradient;
  @include containerShadow;
  box-sizing: border-box;
  display: inline-grid;
  justify-content: center;
  width: 100%;
  min-width: 280px;
  max-width: calc(100% - 36px);
  height: 198px;
  padding-top: 30px;
  padding-bottom: 30px;
  grid-column: 8/-1;

  @media screen and (max-width: $large) {
    grid-column: 1/-1;
  }
}

