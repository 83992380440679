@import './_colors';

.imgSize {
    width: 150px;
    height: auto;
    margin: 0 auto;
}

.centerBot {
    display:flex;
    flex-direction: column;
}

.icon {
    color: white;
    font-size: 40px;
}

.iconContainer {
    width: 240px;
    display: flex;
    justify-content: space-between;
}

.copyright {
    color: $off_white;
    display: block;
    margin: 24px auto;
    width: 180px;
    text-align: center;
}
