@import 'Common.module';

.containerNF {
 @include blue-gradient;
  overflow: hidden;
  padding-top: 3vh;
  min-height: calc(100vh - 64px);
}

.mainContainer {
    width: 540px;
    margin: 5vh auto 0 auto;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.header2,  .header1 {
    text-align: center;
    color: white;
    margin-bottom: 2vh;
}

.header1 {
    font-size: 40px;
}

.text {
    color:white;
}
.background {
    background-color: $light-blue-bg;
}

.link {
 color: white;
 text-decoration: underline;
 cursor: pointer;
}
