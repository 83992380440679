/* Global Styles */


a {
    text-decoration: none;
}

.uppercase {
    text-transform: uppercase;
}

button > * {
    pointer-events: none;
}

table {
    margin: 0 auto;
}

table tr {
    line-height: 24px;
}


/* Login Page Styles */

.login-info-span {
    font-style: italic;
    grid-column: 4/end;
    font-Size: 0.8em;
    align-Self: center;
    margin-Left: 12px;
    padding-Bottom: 10px;
}

/* Customer Quick Guide Styles */

.contact-us-form {
    grid-column: 1/7;
    padding: 1vw 2vw;
    display: grid;
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
}

.contact-us-form>div {
    grid-column: 1 / span 2;
}

@media screen and (max-width: 656px) {
    .contact-us-form {
        grid-column: 1 / -1;
    }
}

/* Main Styles */
.section-container {
    display: grid;
    grid-Template-Columns: repeat(3, 1fr);
    align-items: center;
    grid-Gap: 12px;
    background: #EEE;
    margin: 0;
    padding: 24px;
}

.section-card:hover {
    cursor: pointer;
    transition: 0.5s;
    box-shadow: 0px 2px 5px #777;
}

.section-card p {
    opacity: 0;
    top: 50px;
    position: relative;
}

.section-card:hover p {
    opacity: 1;
    top: 0;
    -webkit-animation: animate_text 0.8s ease-out;
    animation: animate_text 0.8s ease-out;
}

div+.error-container {
    grid-column: span 1;
    color: #dc143c;
    line-height: 25px;
}

@keyframes animate_text {
    from {top: 50px; opacity: 0}
    to {top: 0px; opacity: 1}
}

@media all and (max-width: 1072px) {
    .section-container {
        grid-Template-Columns: repeat(2, 1fr);
    }
}

@media all and (max-width: 710px) {
    .section-container {
        grid-Template-Columns: 1fr;
    }
    .login-info-span {
        grid-column: 1/end;
    }
}
