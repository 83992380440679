@import './variables.scss';
@import './Common.module';

.formContainer {
    display: grid;
    grid-column: 1/end;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, auto);
    background: white;
    border-radius: 4px;
    padding: 24px;
    min-height: 226px;
    max-width: 426px;;
    margin: 0 auto;
    overflow: hidden;
}

.formTitle {
    grid-column: 1/8;
    text-align: center;
    margin: 0 auto;
    padding: 0;
}

.container {
    // height: 100vh;
    width: 100vw;
    min-height: 100vh; // Fixes gap on bottom
}

.success {
    text-align: left;
}

.shadow {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    &:hover {
        box-shadow: 0 14px 28px
        rgba(0,0,0,0.25), 0 10px 10px
        rgba(0,0,0,0.22);
    }
}

.registerContainer {
    height: 100vh;
    width: 100vw;
    min-height: 800px;
}

.registerFormContainer {
    @include containerShadow;
    grid-column: 1/end;
    border-radius: 20px;
    padding: 30px 10px;
    background: white;
    overflow: hidden;
    width: 50vw;
    max-width: 426px;
    margin: 0 auto 24px auto;
}

.button {
    margin: 12px;
    min-width: 10vw;
}

.formField {
    width: 96%;
    margin: 8px auto;
    display: block;
    grid-column: 1/7;
}

.buttonContainer {
    grid-column: 1/-1;
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 768px) {
    .registerFormContainer {
        width: 75vw !important;
    }
}

@media only screen and (max-width: 487px) {
    .registerFormContainer {
        width: 88vw;
    }
    .formContainer {
        max-width: 80vw;
    }
}

@media only screen and (max-width: 350px) {
    .registerFormContainer {
       width: 95vw;
    }
    .formContainer {
        max-width: 80vw;
    }
}
