$black: #111111;
$gray: #5b5b5b;
$red: #B50000;
$green: #004D40;
$wiline_blue: #074481;
$dark_gray: #2b2b2b;
$toolbar-bg: #FFFFFF;
$light_gray: #c4c4c4;
$submit_color: #074481;
$off_white: #F6F6F6;
$mid_gray: #AAAAAA;
$success-background: #239B56;
$error-background: #F9D6D1;
$text_gray:  #474747;

$light_blue: #2E72B7;
$light_blue_bg: #E9F1F8;
$orange: #FFAA00;

// Appbar colors
$pink: rgb(240, 98, 146);
$grey: rgb(120, 144, 156);
$purple: rgb(92, 107, 192);
