@import './variables.scss';
@import './Common.module';

.table {
  display: table;
}

.tableRow {
  align-Items: center;
  background-color: #FAFAFA;
  border-bottom: 1px solid #DDD;
  border-top: 1px solid #DDD;
  cursor: pointer;
  display: flex;
  margin: 0.5vh 0;
  padding: 1vw 0;
}

.tableRow:hover {
  background-color: #f2f2f2;
}

.tableRow.active {
  background-color: #e8e8e8;
}

.tableCell {
  display: table-cell;
}

.layout {
  margin-top: $app-bar-height;
  display: flex;
  flex-direction: row;
  background-color: #E9F1F8;
  height: calc(100% - #{$app-bar-height});
  min-height: calc(100vh - #{$app-bar-height})
}

.gridFix {
  margin: 0 auto; // Fixes multiple account layout when aside doesn't exist
  width: 96%;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}

.container {
  width: calc(100vw - 260px);
  //min-height: calc(100vh - 100px);
  overflow: visible;
  padding-top: 2vw;
}

.shadow {
  @include containerShadow;
}

// ############# Select Billing Account Section   #############
.contentContainer {
  margin: 0 auto;
  padding: 0px 12px;
}

.contentDescription {
  color: white;
  padding: 12px;
  text-align: center;
}

.formContainer {
  margin: 8px auto;
  min-height: 35vh !important;
  overflow-y: auto;
}

.tableBillNo {
  box-sizing: border-box;
  padding-left: 12px;
  width: 20%;
}

.tableOrgName {
  text-align: left;
  width: 63%;
  min-width: 200px;
}

.subsidiary {
  text-align: right;
  padding-right: 2vw;
  width: 10%;
}

.centerText {
  padding-left: 30px;
}

.subsidiaryFilter {
  min-width: 100px;
  margin-right: 2vw;
}

.buttonContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2vw;
  justify-content: space-between;
  padding: 2vh 1vw;
  background: $white;
}

.searchContainer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 12px;
  padding: 1vw;
  width: 90%;
  max-width: 90%;
}

.searchField {
  display: inline-block;
  grid-column: 1/span 8;
  width: 100%;
  margin: 0 auto !important;
  @media screen and (max-width: $small) {
    grid-column: 1/-2;
  }
}

.admin {
  grid-column: 1/span 8;
  @media screen and (max-width: $small) {
    grid-column: 1/-1;
  }
}

.multi {
  grid-column: 1/-1;
}

.selectField {
  display: inline-block;
  grid-column: 9/-1;
  width: 100%;
  margin: 0 auto !important;
  @media screen and (max-width: $small) {
    grid-column: 1/-1;
  }
}

.multipleAccountsTable {
  line-height: 24px;
  vertical-align: center;
  display: block;
  width: 100%;
  margin: 24px auto;

  tbody tr {
    display: block;
    width: auto;
    margin: 0 auto;
    border-bottom: 1px solid #eee;
  }
}

.tableSize {
  width: 95%;
  max-width: 960px;
  overflow: auto;
  margin-bottom: 80px;

  &::-webkit-scrollbar {
    width: 0
  }

  @include containerShadow;
  @media screen and (max-width: $small) {
    max-height: 80vh;
    max-width: 98vw;
  }
}

.loader {
  min-Height: 300px;
  font-Size: 4em;
  color: #074481;
  display: flex;
  justify-Content: center;
  align-Items: center;
}

.changePasswordSpinner {
  width: 100%;
  font-Size: 24px;
  display: flex;
  justify-content: center;
  margin: 8px 0;
}

// media queries for the switch-accounts table
@media only screen and (max-width: 468px) {
  .tableBillNo {
    box-sizing: border-box;
    min-width: 120px;
  }

  .tableOrgName {
    text-align: left;
    max-width: 150px;
    word-break: break-word;
    box-sizing: border-box;
    padding-right: 16px;
  }
}

@media only screen and (max-width: 350px) {
  .buttonContainer {
    max-width: 260px;
  }
}

@media only screen and (max-width: 320px) {
  .tableBillNo {
    box-sizing: border-box;
    min-width: 120px;
    padding-left: 0px;
  }

  .tableOrgName {
    text-align: left;
    min-width: 130px;
    word-break: break-word;
    box-sizing: border-box;
    padding-right: 16px;
  }

  .buttonContainer {
    max-width: 240px;
  }
}

/////////////// add media query for change billing address form /////////////

.contactFormLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
  @media only screen and (max-width: 736px) {
    grid-template-columns: 1fr;
  }
}

.billingAddressMobileFix {
  width: 49%;
  @media only screen and (max-width: 450px) {
    width: 100%;
  }
}

.billingAddressMobileContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
