@import './variables.scss';
@import './Common.module';

.green {
  color: green !important;
}

.red {
  color: red !important;
}

.visibilityIcon {
  margin-right: 6px;
}

.download {
  color: #074481; /* Main brand color or other accent color */
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
}

.download:hover {
  color: #0466c8; /* Slightly different color on hover */
  text-decoration: underline;
}

.download::after {
  content: ' ⬇️'; /* Small download icon, or replace with SVG */
  margin-left: 5px;
  font-size: 0.8em;
}

.cell {
    @include center-all;
    padding: 8px;
}

.data {
    color: $text-gray;
    text-align: center;
}

.row {
    @include buttonShadow;
    background-color: white;
}

.tableContainer {
    @include containerShadow;
    overflow: auto;
    width: 100%;
}

.endlessFooter {
    background-color: white;
    border-top: 1px solid lightgray;
    font-size: 16px;
    font-weight: 600;
    padding: 18px;
    text-align: center;
}
