//Imports
@import './variables';
@import './Common.module';

// Necessary override to display title.
.welcomeHelp {
  margin-top: 70px !important;
}

// Form Styles ContactUsForm.js

.formContainer {
  padding: 0 2vw;
  background: $light_blue_bg;
  width: calc(100vw - 260px);
  margin: 0 auto;

  .contactForm {
    grid-column: 1/-1;
	grid-row-gap: 2vh;
    padding: 3vh 2vw;
    display: grid;
    margin: 0 auto;
    width: 90%;
  }

  .buttonContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10vw;
    justify-content: space-between;
  }

  @media screen and (max-width: 599px) {
    width: 100%;
    .contactForm {
      width: 98%;
    }
  }
}

// for Title Bar
.history {
  background-color: white;
  display: flex;
  justify-content: flex-end;
}

.paperForm {
  @include containerShadow;
  width: 100%;
  height: 100%;
  display: grid;
}

// Contacts Vertical Styles ContactsVertical.js
.contactList {
  background: $white;
  list-style-type: none;
  padding-top: 0;
  grid-column: 9/span 2;

  .title {
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
  }

  .listItem {
    border-radius: 4px;
    padding: 8px 12px;
    margin-bottom: 8px;
    a {
        text-decoration: none;
        color: inherit;
    }
  }

  .listHeading {
    margin-bottom: 8px;
  }
}

.paperList {
  @include containerShadow;
  width: 100%;
  height: 100%;
  padding: 12px 12px;
  flex: 1;
}

// Buttons
.button {
  margin: 1vw 4vw;
}

//  ################ Simple Guide ####################

.guideList {
  grid-Column: span 3;

  ul {
    list-style-type: none;
  }

  ul li {
    line-height: 2em;
  }
}

.sectionContent:nth-child(odd) {
  background: whitesmoke;
}

.sectionContent {
  display: grid;
}

.guideIcon {
  grid-column: span 1;
  display: flex;
  justify-content: center;
}

.guideContent {
  grid-column: 1/-1;
  display: grid;
  grid-Template-Columns: repeat(4, 1fr);
  padding: 1vh 5vw;
  align-Items: center;
}

@media all and (max-width: 1024px) {
  .guideContent > * {
    grid-column: 1/-1;
    padding: 2vh 2vw;
  }
}

.confirmationMessage {
  padding: 30px;

  p {
    padding: 30px;
  }

  button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}



